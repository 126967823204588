import axios from "axios";
import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  IS_VERIFYING,
  PDFFILE_BASE64_DATA,
  UNSUPPORTED_MEDIA,
  DOCUMENT_NOT_ORIGINAL,
  SOMETHING_WENT_WRONG,
} from "./actionTypes";
import { PdfToBase64 } from "../helpers/pdfFileChangerHandler";
import { sendLogs } from "../helpers/sendLogs";
import { sentryAlert } from "../helpers/sentry_logs";
import { env as environment } from "../environments";
import { s3fileHandler } from "./uploads3_Action";
import { updateSession } from "../helpers/updateSessionHandler";

export const uploadFileAction = (file, paramsData, category, sessionid) => {
  const params = paramsData;

  return async (dispatch) => {
    // var category = localStorage.getItem('category');
    var sesionId = localStorage.getItem("sesionId");
    var sessiondataObj = {
      sessionid: sessionid,
      data: {
        indicator: {
          progress_no: "2",
          progress_name: "Tried upload",
          Detection: "Upload",
        },
      },
    };

    const s3UpdateSession = {
      sessionid: sessionid,
      data: {
        s3filename: [
          sessionid + "-" + "upload" + ".pdf"
        ]
      }
    }

    const formData = new FormData();
    formData.append("files", file);

    try {
      const base64Data = await PdfToBase64(file);
      dispatch({ type: PDFFILE_BASE64_DATA, payload: base64Data });
      dispatch(isVerifying(true));
      if (params.buttonid) {
        dispatch(updateSession(sessiondataObj, sesionId));
        dispatch(updateSession(s3UpdateSession));
      }
      const response = await callVerifyHashAPI(
        formData,
        dispatch,
        sessionid,
        file,
        category,
        params
      );
      // if the document is already verified then we will not call the s3 file handler
      if (response.data.error === false) {
        var documentVarified = {
          sessionid: sessionid,
          data: {
            indicator: {
              progress_no: "3",
              progress_name: "Document already verified",
              Detection: "Uplaod",
            },
          },
        };
        if (params.buttonid) {
          dispatch(updateSession(documentVarified, sessionid));
        }
      }
    } catch (error) {
      // var SentryLogMessage = {
      //   version: "1.0",
      //   host: "pdf verifier upload Process",
      //   short_message: "Hash verification Failure",
      //   full_message: `Hash verifiy failure with the following error: ${error.messsage}`,
      //   timestamp: Date.now() / 1000,
      //   level: 3,
      //   facility: "uploadAction.js",
      //   _sessionid: sessionid,
      //   app_name: "pdf upload Verifier ",
      //   _ENV: "stage2",
      // };
      dispatch(uploadFileFailure(error));
      await sendLogs(
        "uploadFileFailure",
        "failing in hashing verification.",
        "uploadActions.js",
        sesionId,
        "1"
      );
      sentryAlert(error, sesionId, "verifyHash API ");
    }
  };
};

// Function to call verifyHash API
const callVerifyHashAPI = async (
  formData,
  dispatch,
  sessionId,
  file,
  category,
  params
) => {
  try {
    const response = await axios.post(`${environment.verifyHash}`, formData);
    if (Object.keys(params).length < 1) {
      if (response.data.message === "not found") {
        await sendLogs(
          "Verify Document: ",
          "document not found!",
          "uploadAction.js",
          sessionId,
          "1"
        );

        dispatch({ type: DOCUMENT_NOT_ORIGINAL, payload: true });
      } else {
        await sendLogs(
          "Verify Document: ",
          "hash varified and document found!",
          "uploadAction.js",
          sessionId,
          "1"
        );
      }
    }
    dispatch(uploadFileSuccess(response?.data));

    // if the document is not verified then we will call the s3 file handler
    if (
      response.data.message === "not found" &&
      Object.keys(params).length > 0
    ) {
      await sendLogs(
        "Verify Document: ",
        "hash verified & document not found",
        "uploadAction.js",
        sessionId,
        "1"
      );
      
    s3fileHandler(file,sessionId,category);
    
    dispatch({type:DOCUMENT_NOT_ORIGINAL,payload:true})
    }
    // if the document is already verified then we will not call the s3 file handler
    else if (
      response.data.message !== "not found" &&
      Object.keys(params).length > 0
    ) {
      await sendLogs(
        "Verify Document: ",
        "hash verified & document found",
        "uploadAction.js",
        sessionId,
        "1"
      );
    }

    return response;
  } catch (error) {
    console.log("response upload action error",error);
    var SentryLogMessage = {
      version: "1.0",
      host: "pdf verifier upload Process",
      short_message: "verifyHashAPI Failure",
      full_message: `error during verifyHash API call with the following error: ${error}`,
      timestamp: Date.now() / 1000,
      level: 3,
      facility: "uploadAction.js",
      _sessionid: sessionId,
      app_name: "pdf upload Verifier ",
      _ENV: "stage2",
    };
    dispatch(uploadFileFailure(error));
    dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
    await sendLogs(
      "verifyHashAPI Failure",
      "Error during verifyHash API call",
      "uploadActions.js",
      sessionId,
      "1"
    );
    sentryAlert(error, sessionId, "verifyHash API ");
    throw error; // Re-throw the error to be handled by the caller
  }
};

const uploadFileSuccess = (data) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
});

const uploadFileFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
});

const isVerifying = (bool) => ({ type: IS_VERIFYING, payload: bool });

export const unsupportedMedia = (bool) => ({
  type: UNSUPPORTED_MEDIA,
  payload: bool,
});
